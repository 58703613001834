<template>
    <div>
        <div class="container relative">
            <div class="sm:pt-6 sm:pb-24">
                <div class="flex justify-between">
                    <slot name="breadcrumbs" />
                </div>

                <section class="grid grid-cols-1 gap-x-8 md:gap-y-10 md:mt-5 lg:grid-cols-5">

                    <!-- Filtreleme Alanı -->
                    <slot name="filter" />

                    <div class="relative" :class="[isShowFilter ? 'lg:col-span-4' : 'lg:col-span-5']">

                        <!-- Header -->
                        <slot name="header" />

                        <!-- Ürün listeleme bölümü -->
                        <div class="product-listing-wrapper" :class="mobileWrapperClass" v-if="totalResults > 0">
                            <template v-for="product in items">
                                <LayoutComponentProductCard :product="product"/>
                            </template>
                        </div>

                        <slot name="emptyArea" />

                        <slot />

                    </div>
                </section>

                <!-- HTML Açıklama Alanı -->
                <slot name="htmlContentArea" />
            </div>
        </div>
    </div>
</template>

<script setup>
const storeConfig = useStoreConfig()
const { currency } = storeToRefs(storeConfig)

const {isShowFilter, isHideBreadCrumbs} = useHelper()

const {items, currentPage, perPage, totalResults, totalPage, baseParams, nextPage, prevPage, orderBy} = usePagination()
const {isDouble} = useListing()
const mobileListingType = useState('mobileListingType', () => isDouble.value ? 2 : 1)

const mobileWrapperClass = computed(() => {
  return mobileListingType.value === 2 ? 'grid-cols-2' : 'grid-cols-1'
})
</script>